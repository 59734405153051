'use client'

import React from "react";
import { motion } from 'framer-motion';
import { Typography, Container, Grid } from '@mui/material';
import Image from "next/image";
import Link from 'next/link';

const OrderImages = (images) => {
    const numRows = 3; // Number of row in the grid
    const numCols = Math.ceil(images.length / numRows); // Calculate the number of columns needed
    const ordered = new Array(images.length);

    for (let i = 0; i < images.length; i++) {
        const row = i % numRows;
        const col = Math.floor(i / numRows);
        ordered[row * numCols + col] = images[i];
    }

    return ordered;
};
export const HeaderSection = ({ data }) => {
    const orderedImages = OrderImages(data);

    return (
        <motion.section
            className="header-section"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <div className="section-body">
                <Container maxWidth='full' className="no-padding">
                    <Typography variant="h3" component="h1" gutterBottom hidden>
                        Marlene Gujan Architektur AG
                    </Typography>
                    <Grid container spacing={1} justifyContent="center">
                        {orderedImages.map((item, index) => {
                            let projectUrl = `/projekte/${encodeURIComponent(item.projekt)}`;
                            if (item.projekt === '111' || item.projekt === '126' || item.projekt === '108') {
                                projectUrl = '/projekte'
                            }
                            return (
                                <Grid container item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                                    <Link href={projectUrl} style={{ textDecoration: 'none', height: '100%', width: '100%' }}>
                                        <Image
                                            alt={item.image.name}
                                            className="object-cover"
                                            src={item.image.url}
                                            width={item.image.width}
                                            height={item.image.height}
                                            loading="lazy" />
                                    </Link>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </div>
        </motion.section>
    );
};
